<template>
  <page-view title="电子签名" left-arrow white-bg>
    <div class="signature-pad">
      <div class="control-buttons">
        <van-button type="default" class="clearBtn"  @click="clear">重写</van-button>
        <van-button type="primary" class="submitBtn"  @click="saveImage">完成</van-button>
      </div>
      <div class="canvas-panel">
        <canvas
          id="sign-canvas"
          :disable-scroll="true"
          :width="canvas.width"
          :height="canvas.height"
          @touchstart="onCanvasTouchStart"
          @touchmove="onCanvasTouchMove"
          @touchend="onCanvasTouchEnd"
          :style="{
            zIndex: 9
          }"
        ></canvas>
      </div>
    </div>

    <!-- 签名预览 - 调试用 -->
    <!-- <div>
      <img :src="myImg" alt="" style="max-width: 100%;">
    </div> -->
  </page-view>
</template>

<script>
class Coordinate {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

class PenPoint {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

import { mapGetters } from 'vuex';
import { parseTime } from '@/utils'

import { dzhtService_createDzhtPdf , dzhtService_uploadYhqmImg} from '@/api/psdmsykgl/service/DzhtServiceAPI'
import { fileManage_upload, fileManage_getList, fileManage_delete} from '@/utils/fileManage';
import axios from 'axios';
function preventDefaultEvent(e) {
  e.preventDefault();
}
export default {
  name: "signature",

  data() {
    return {
      beginPoint: {},
      points: [],
      ctx: null,
      canvas: {
        width: 375,
        height: 1080,
      },
      canvas_fix: {
        width: 300,
        height: 100,
      },
      moveFlag: false,
      clientHeight: 0,
      // 裁剪区域
      cutPoints: null,
      myImg: '',

      // 业务变量
      htxx: {
        jgbm: null,
        qdrq: null,
        ywbh: null,
        yhbh: null,
        htbh: null
      }
    };
  },

  computed: {
    ...mapGetters(['token','jgbm','dybm','czyh','dlzh','yhid','yhsj']),
  },

  methods: {
    /** 计算量点之间距离
     * @param {Object} a
     * @param {Object} b
     */
    getDistance(a, b) {
      let x = b.x - a.x;
      let y = b.y - a.y;
      return Math.sqrt(x * x + y * y);
    },
    drawLine(beginPoint, controlPoint, endPoint) {
      let ctx = this.ctx;

      ctx.beginPath();
      ctx.moveTo(beginPoint.x, beginPoint.y);
      ctx.quadraticCurveTo(
        controlPoint.x,
        controlPoint.y,
        endPoint.x,
        endPoint.y
      );
      ctx.stroke();
    },

    clear() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.cutPoints = null;
    },
    // 计算裁剪区域
    calcCutArea(point) {
      const { x, y } = point;
      if (this.cutPoints) {
        let leftTopPoint = this.cutPoints[0];
        let rightBottomPoint = this.cutPoints[1];

        if (x < leftTopPoint.x) {
          leftTopPoint.x = x;
        }
        if (y < leftTopPoint.y) {
          leftTopPoint.y = y;
        }
        if (x > rightBottomPoint.x) {
          rightBottomPoint.x = x;
        }
        if (y > rightBottomPoint.y) {
          rightBottomPoint.y = y;
        }

        this.cutPoints = [leftTopPoint, rightBottomPoint];
      } else {
        this.cutPoints = [new Coordinate(x, y), new Coordinate(x, y)];
      }

      // console.log(this.cutPoints);
    },

    onCanvasTouchStart(e) {
      let ctx = this.ctx;
      // console.log("START: ", e, ctx);

      let point = new PenPoint(e.touches[0].clientX - 60, e.touches[0].clientY - 58);
      this.points.push(point);
      this.beginPoint = point;
      this.moveFlag = true;
    },
    onCanvasTouchMove(e) {
      // console.log("MOVE:",e, this.points);
      if (!this.moveFlag) return;
      let ctx = this.ctx;

      let point = new PenPoint(e.touches[0].clientX - 60, e.touches[0].clientY - 58);
      this.points.push(point);
      this.calcCutArea(point);

      if (this.points.length > 3) {
        const lastTwoPoints = this.points.splice(-2);
        const controlPoint = lastTwoPoints[0];
        const endPoint = new PenPoint(
          (lastTwoPoints[0].x + lastTwoPoints[1].x) * 0.5,
          (lastTwoPoints[0].y + lastTwoPoints[1].y) * 0.5
        );

        this.drawLine(this.beginPoint, controlPoint, endPoint);
        this.beginPoint = endPoint;
      }
    },
    onCanvasTouchEnd(e) {
      // console.log("END: ",e)

      if (!this.moveFlag) return;

      if (this.points.length > 3) {
        const lastTwoPoints = this.points.slice(-2);
        const controlPoint = lastTwoPoints[0];
        const endPoint = lastTwoPoints[1];
        this.drawLine(this.beginPoint, controlPoint, endPoint);
      }
      this.beginPoint = null;
      this.points = [];
      this.moveFlag = false;
    },

    // 上传签名文件
    uploadImage(imageUrl) {

      return new Promise((resolve, reject) => {
        const {jgbm, yhbh, htbh, qdrq, ywbh, dzhtzt} = this.htxx;

        let fileRaw = this.base64ToFile(imageUrl, `${yhbh}-${htbh}-signature.png`);
       
        let formData = new FormData();
        formData.append('file',fileRaw);
        formData.append('fileName', htbh+'');
        formData.append('qdrq', qdrq);
        formData.append('ywbh', ywbh);
        formData.append('jgbm', jgbm);
        formData.append('qdrq', qdrq);
        formData.append('yhbh', yhbh);
        formData.append('dzhtzt', dzhtzt);
        // 是否续签合同
        formData.append('flag', dzhtzt=='3'?'4':'2');

        let uploadUrl = '/psdmsykgl/fileDzht/uploadQmtpAndCreatePDF';
        let headers = {
          'Content-Type': 'multipart/form-data',
          'loginToken': this.token
        };
        
        axios.post(uploadUrl, formData, {
          headers,
          onUploadProgress:function(progressEvent){},
        }).then(response => {
          console.log(response);
          const respData = response.data;
          if (respData.code !== 2000) {
            throw new Error(respData.message);
          }
          if (respData.content && respData.content.status === 200) {
            resolve(respData.content.message);
          }  else {
            throw new Error(respData.content.message);
          }
        }).catch(err => {
          console.log(err);
          reject(err);
        });
      })
    },

    handleSignature(fileId) {
      let loading = this.$toast.loading({
        message: '请稍后...',
        duration: 0
      });
      let flag = '2'; // // flag: 1-创建, 2-签名
      const { qdrq, ywbh, jgbm, yhbh, dzhtzt} = this.htxx;
      if (dzhtzt == '3') {
        flag = '4'; // 续签合同
      }
      dzhtService_createDzhtPdf(qdrq, ywbh, jgbm, yhbh, flag, fileId).then(res => {
        
        loading.close();
        if (res.status && res.status == 400) {
          throw new Error(res.message);
        }
        if (res.content && res.content.status == 400) {
          throw new Error(res.content.message);
        }
        this.$toast.success("已签名！");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }).catch(err => {
        loading.close();
        this.$dialog.alert({
          title: '提示',
          message: '签名失败:'+ err.message
        });
      });
    },

    cutImg(content, width, height) {
      let cutCanvas = document.createElement('canvas');
      let ctx = cutCanvas.getContext('2d');
      cutCanvas.height = height;
      cutCanvas.width = width;

      ctx.putImageData(content, 0 , 0);
      return cutCanvas.toDataURL();
    },
    _loadImage: function _loadImage(data, callback) {
      var image = new Image();
      image.src = data;
      image.onload = function () {
        callback(image);
      };
      image.onerror = function () {
        console.log('Error: image error!');
      };
    },
    _getCanvas: function _getCanvas(width, height) {
      var canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      return canvas;
    },
    rotateImg(src, degrees, callback) {
      var _this = this;
      this._loadImage(src, function (image) {
        var w = image.naturalWidth;
        var h = image.naturalHeight;
        var canvasWidth = Math.max(w, h);
        var cvs = _this._getCanvas(canvasWidth, canvasWidth);
        var ctx = cvs.getContext('2d');
        ctx.translate(canvasWidth / 2, canvasWidth / 2);
        ctx.rotate(degrees * (Math.PI / 180));
        var x = -canvasWidth / 2;
        var y = -canvasWidth / 2;
        degrees = degrees % 360;
        if (degrees === 0) {
          return callback(src, w, h);
        }
        var sx = 0;
        var sy = 0;
        if ((degrees % 180) !== 0) {
          if (degrees === -90 || degrees === 270) {
            x = -w + canvasWidth / 2;
          } else {
            y = canvasWidth/2 - h;
          }
          const c = w;
          w = h;
          h = c;
        } else {
          x = canvasWidth/2 - w;
          y = canvasWidth/2 - h;
        }
        ctx.drawImage(image, x, y);
        var cvs2 = _this._getCanvas(w, h);
        var ctx2 = cvs2.getContext('2d');
        ctx2.drawImage(cvs, 0, 0, w, h, 0, 0, w, h);
        var data = cvs2.toDataURL('image/png', 1);
        callback(data, w, h);
      });
    },
    
    async saveImage() {
      const _this = this;
      let ctx = this.ctx;
      ctx.save();

      if (this.cutPoints) {
        let loading = this.$toast.loading({
          message: '请稍后...',
          duration: 0
        });
        let x1 = this.cutPoints[0].x - 20,
          y1 = this.cutPoints[0].y - 20;

        let x2 = this.cutPoints[1].x + 20,
          y2 = this.cutPoints[1].y + 20;

        let imgWidth = x2 - x1;
        let imgHeight = y2 - y1;

        console.log(this.cutPoints);

        let imgData = this.ctx.getImageData(x1, y1, imgWidth, imgHeight,{});
        
        let imgUrl =  this.cutImg(imgData, imgWidth, imgHeight)
        this.rotateImg(imgUrl, 270, function(imgUrl, w, h) {

          _this.myImg = imgUrl;
          _this.uploadImage(imgUrl).then(message => {
            loading.clear();
            // _this.handleSignature(fileUrl);
            _this.$toast.success(message);
            setTimeout(() => {
              _this.$router.go(-1);
            }, 1000);
          }).catch(err => {
            loading.clear();
            _this.$dialog.alert({
              title: "提示",
              message: '签名文件上传失败:'+ err.message
            });
          });
        });

      } else {
        this.$toast("请先签名");
      }
    },

    // base64转化为file
    base64ToFile: function(urlData, fileName){
        let arr = urlData.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bytes = atob(arr[1]);
        let n = bytes.length
        let ia = new Uint8Array(n);
        while (n--) {
            ia[n] = bytes.charCodeAt(n);
        }
        return new File([ia], fileName, { type: mime });
    }
  },

  created() {
    this.clientWidth = screen.width;
    this.clientHeight = screen.height;
    let width = screen.width - 80;
    let height = screen.height - 80;

    // 横屏显示， 需要交换宽高
    this.canvas = {
      width: width,
      height: height,
    };

    console.log(this.clientHeight, this.canvas);
    document.body.addEventListener('touchmove', preventDefaultEvent,{passive: false});
  },

  mounted() {
    const lineWidth = 10;
    const lineColor = "#000000";

    if (this.$route.query) {
      this.htxx = this.$route.query;
      let qdrq = this.$route.query.qdrq;
      if (qdrq && !qdrq.match(/ 00:00:00$/)) {
        this.htxx.qdrq = qdrq + " 00:00:00";
      }
    }
    this.$nextTick(function() {
      let ctx = document.getElementById("sign-canvas").getContext("2d");
  
      ctx.lineWidth = lineWidth;
      ctx.fillStyle = lineColor;
      ctx.strokeStyle = lineColor;
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
  
      // 消除锯齿
      ctx.shadowBlur = 1;
      ctx.shadowColor = "#000000";
  
      this.ctx = ctx;
      
      this.cutPoints = null;

      
    })
  },
  destroyed() {
    document.body.removeEventListener('touchmove', preventDefaultEvent,{passive: false});
  }
};
</script>


<style lang="scss" scoped>

.signature-pad {
  display: flex;
}
.canvas-panel {
  position: relative;
  background-color: #f1f2f7;
  border-radius: 6px;
  border: 2px dashed #b3b3b3;
  margin: 10px;
  margin-left: 0;
  overflow: hidden;
  z-index: 1;
}

.canvas-panel::after {
  font-size: 36px;
  color: #c1c2c6;
  left: 50%;
  top: 50%;
  position: absolute;
  content: "请在此处签名";
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
  letter-spacing: 20px;
  white-space: nowrap;
  z-index: -1;
}

.control-buttons {
  width: 60px;

  .van-button {
    transform: rotate(90deg);
    transform-origin: 40px 0;
    width: 80px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    position: absolute;
    left: 10px;
  }
  .clearBtn {
    bottom: 100px;
  }
  .submitBtn {
    bottom: 10px;
  }
}
</style>